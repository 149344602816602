<template>
  <v-app>
    <h1 class="text-center text-gold mb-10">{{ $t('Liked Posts') }}</h1>
    <Post v-for="(post, i) in posts" :key="i" class="mb-11" :post="post" :color="postColor"
      @postLikeToggle="postLikeToggled"
    />
  </v-app>
</template>
<script>
import { showSnackBar } from '@/services/snackbar'

export default {
  name: 'LikedPosts',
  components: {
    Post: () => import('@/components/Post'),
  },
  data: () => ({
    user: null,
    posts: [],
    postColor: '',
    storeWatchers: {
      posts: null,
      user: null,
      color: null,
    } 
  }),
  created () {
    this.$store.dispatch('post/getPosts').then(() => {
      this.posts = this.$store.getters['post/posts']
      this.watchAndSetPosts() 
    })

    this.user = this.$store.getters['user/user'] // User here isnt inside an dispatch promise result due firebase onAuthStateChanged already doing that dispatch
    this.watchAndSetUser()

    this.postColor = this.$store.getters['app/app'].color.name
    this.watchAndSetColor()
  },
  beforeDestroy () {
    this.storeWatchers.posts()
    this.storeWatchers.user()
    this.storeWatchers.color()
  },
  methods: {
    watchAndSetPosts () {
      this.$store.dispatch('post/watchPosts') 
      this.storeWatchers.posts = this.$store.watch(
        state => state.post.posts,
        posts => { this.posts = posts.filter(post => post.liked === true) },
        { deep: true } 
      )
    },
    watchAndSetUser () {
      this.storeWatchers.user = this.$store.watch(
        state => state.user.user,
        changedUser => { 
          this.user = changedUser
          const likedPosts = changedUser?.likedPosts || []
          this.setLikeOnPosts(likedPosts)
        },
        { deep: true } 
      )
    },
    watchAndSetColor () {
      this.storeWatchers.color = this.$store.watch(
        state => state.app.color,
        color => { this.postColor = color.name },
        { deep: true } 
      )
    },
    setLikeOnPosts (likedPosts) {
      likedPosts.forEach(postID => {
        const foundIndex = this.posts.findIndex(findingPost => findingPost.id === postID)

        if (foundIndex > -1) 
          this.posts[foundIndex].liked = true
      })
    },
    postLikeToggled (post) {
      showSnackBar({
        message: 'You have unliked a post. Title: ',
        dynamicMessage: post.title ? `"${post.title}"` : '',
        config: {
          color: 'orange',
          timeout: 3500
        }
      })
      this.updateUserLikedPosts(post)   
    },
    async updateUserLikedPosts (post) {
      let likedPosts = this.$store.getters['user/user']?.likedPosts || []

      if (post.liked) 
        likedPosts.push(post.id)
      else 
        likedPosts = likedPosts.filter(filteringPost => filteringPost !== post.id)

      await this.$store.dispatch('user/updateUser', { likedPosts })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_classes.scss';
  h1 { 
    font-family: 'Pacifico', cursive; 
    text-shadow: 1px 1px 1px gray;
  }
</style>